import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TbInput from './TbInput';
import TbTextarea from './TbTextarea';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { postCredit, ReqCreditUpdate } from '../../../api/creditAPI';
import { toast } from 'react-toastify';

// import { formatPrice } from '../../../utils/formatUtils';

interface creditModalFormData {
  credit: number;
  description: string;
}

interface ModalProps {
  isOpen: boolean;
  idBrand: number;
  isCreditAdd: boolean;
  brandCredit?: number; // 소지한 크레딧
  headerTitle?: string;
  toggle?: () => void; // 부모 컴포넌트에서 모달 상태를 변경하는 함수'
  onSuccess?: () => void;
}

function TbCreditModal({ isOpen, idBrand, brandCredit, isCreditAdd, headerTitle, toggle, onSuccess }: ModalProps) {
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() + 1)); // 1년 추가
  });

  //  Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // watch,
  } = useForm<creditModalFormData>({});

  // 다국어 모드 값 감시
  // const creditData = watch('credit');

  // API Patch ::: Update
  const patchCreditMutation = useMutation((requestData: ReqCreditUpdate) => postCredit(requestData), {
    onSuccess: (data) => {
      console.debug('Category updated successfully:', data);
      if (toggle) {
        toggle();
      }
      toast.success(`${isCreditAdd ? '적립' : '차감'} 완료되었습니다.`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 모달 꺼졌을때 초기화.
  useEffect(() => {
    reset({
      credit: 0, // 초기화할 값 설정
      description: '', // 초기화할 값 설정
    });
  }, [isOpen]);

  // 차감 / 적립
  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    // 날짜 형식을 'YYYY-MM-DD'로 맞추기
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월을 두 자리로 맞추기
      const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 맞추기
      return `${year}-${month}-${day}`;
    };

    const requestData: ReqCreditUpdate = {
      idBrand: idBrand,
      accumulated: isCreditAdd, // 적립 여부
      expireDate: endDate ? formatDate(endDate) : null, // 만료일 처리
      credit: Number(data.credit),
      description: data.description,
    };
    try {
      // 적립
      if (isCreditAdd) {
        await patchCreditMutation.mutateAsync(requestData);
        // 차감
      } else {
        if (brandCredit) {
          // 소지금 보다 차감금이 더 클때
          if (brandCredit - requestData.credit >= 0) {
            await patchCreditMutation.mutateAsync(requestData);
          } else {
            toast.warn('소지한 크레딧 보다 많은 크레딧은 차감할 수 없습니다.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 10000,
            });
          }
        } else {
          await patchCreditMutation.mutateAsync(requestData);
        }
      }
    } catch (error) {
      useApiError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} size="md" fade={true}>
      {/* Header */}
      {headerTitle && (
        <ModalHeader className={'modal-header'} toggle={toggle}>
          크레딧{' '}
          {isCreditAdd ? <span className={'text-primary'}>적립</span> : <span className={'text-danger'}>차감</span>}
        </ModalHeader>
      )}
      {/*   Body  */}
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <ModalBody className="modal-body">
          <TbInput
            label={isCreditAdd ? '적립 금액' : '차감 금액'}
            name={'credit'}
            type="number"
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          {isCreditAdd && (
            <div className={'mb-3'}>
              <label className="col-form-label">
                만료일<span className={'text-danger'}>(필수)</span>
              </label>
              <DatePicker
                className="datepicker-here form-control"
                selected={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                dateFormat={'yyyy-MM-dd'}
                minDate={new Date()}
              />
            </div>
          )}
          <TbTextarea
            label={'설명'}
            id="memo"
            name={'description'}
            placeholder={`크레딧 ${isCreditAdd ? '적립' : '차감'} 이유를 작성해주세요.`}
            rows={5}
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          {/* 현재 크레딧에서 적립 / 차감 계산 */}
          {/*<div className={'h5'}>*/}
          {/*  Credit : {formatPrice(brandCredit)}*/}
          {/*  {creditData &&*/}
          {/*    (isCreditAdd ? (*/}
          {/*      <>*/}
          {/*        {' '}*/}
          {/*        + {formatPrice(creditData)} = {formatPrice(Number(brandCredit) + Number(creditData))}{' '}*/}
          {/*      </>*/}
          {/*    ) : (*/}
          {/*      <>*/}
          {/*        {' '}*/}
          {/*        - {formatPrice(creditData)} = {formatPrice(Number(brandCredit) - Number(creditData))}{' '}*/}
          {/*      </>*/}
          {/*    ))}*/}
          {/*</div>*/}
        </ModalBody>
        {/* Footer */}

        <ModalFooter className={'modal-footer'}>
          <Button color="danger" onClick={toggle}>
            취소
          </Button>
          <Button color="dark" className="me-3" type={'submit'}>
            {isCreditAdd ? '적립' : '차감'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default React.memo(TbCreditModal);
