import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment/moment';
import { formatPrice } from '../../../utils/formatUtils';

interface PropsType {
  userInfo?: any;
  subscription?: any;
}
/**
 * TODO 구독 상세 정보
 * TODO (구독 상세 페이지에 공통으로 사용됨)
 * */
function SubscriptionInfo({ subscription, userInfo }: PropsType) {
  return (
    <div className="card">
      <div className={'card-header'}>
        <h5>구독정보</h5>
      </div>
      <div className={'card-body'}>
        {!subscription ? (
          <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
        ) : (
          <Row className="details">
            <Col xs="6" className={'detail-item'}>
              구독 유저 ID
              <br />
              <span>
                {userInfo ? userInfo?.id : <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              구독 계정
              <br />
              <span>
                {userInfo ? (
                  <div>
                    {userInfo?.fullName}
                    <br />
                    {userInfo?.email}
                  </div>
                ) : (
                  <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
                )}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              구독 ID
              <br />
              <span>{subscription.id}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              구독 상태
              <br />
              {subscription.status === 'SUBSCRIBE' && (
                <span className={'text-success'}>
                  <i className="fa fa-check-circle-o"></i> 구독중
                </span>
              )}
              {subscription.status === 'EXPIRED' && (
                <span className={'text-danger'}>
                  <i className="fa fa-times-circle-o"></i> 구독만료
                </span>
              )}
            </Col>
            <Col xs="6" className={'detail-item'}>
              납입 주기
              <br />
              <span>
                {subscription.periodType === 'MONTHLY' && '월납'}
                {subscription.periodType === 'YEARLY' && '연납'}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              약정 기간
              <br />
              <span>
                {subscription?.startDate ? moment(subscription?.startDate).format('YYYY.MM.DD') : ''}
                &nbsp;~&nbsp;
                {subscription?.endDate ? moment(subscription?.endDate).format('YYYY.MM.DD') : ''}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              주기
              <br />
              <span>
                {subscription?.periodStartDate ? moment(subscription?.periodStartDate).format('YYYY.MM.DD') : ''}
                &nbsp;~&nbsp;
                {subscription?.periodEndDate ? moment(subscription?.periodEndDate).format('YYYY.MM.DD') : ''}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              구독 취소
              <br />
              <span>
                {subscription?.canceled ? (
                  <span className={'text-danger'}>
                    <i className="fa fa-times-circle-o"></i> 구독취소
                  </span>
                ) : (
                  '-'
                )}
                &nbsp;
                {subscription?.cancelDate ? `(${moment(subscription?.cancelDate).format('YYYY.MM.DD')})` : ''}
              </span>
            </Col>
          </Row>
        )}

        <hr />
        <h6 className="f-w-600 m-b-10 m-t-30">구독 요금제</h6>
        {!subscription ? (
          <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
        ) : (
          <>
            {subscription?.plan?.level > 1 ? (
              <Row className="details">
                <Col xs="6" className={'detail-item'}>
                  요금제 ID
                  <br />
                  <span>
                    {subscription.plan.id} (Lv.{subscription.plan.level})
                  </span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  요금제명
                  <br />
                  <span>
                    {subscription.plan.title}
                    <br />- {subscription.plan.description}
                  </span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  구독료
                  <br />
                  <span>
                    월납: {formatPrice(subscription.plan.price)}원/월 <br />
                    연납: {formatPrice(subscription.plan.price * 12 * (1 - subscription.plan.discountRate))}원/연 (
                    {subscription.plan.discountRate * 100}% 할인)
                  </span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  스펙
                  <br />
                  <span>
                    - 스토리지 : {subscription.plan.storage / 1000}GB
                    <br />- 최대 계정 연동 : {subscription.plan.memberCount} 계정
                  </span>
                </Col>
              </Row>
            ) : (
              <p className={'text-center m-t-15'}>구독 중인 요금제가 없습니다.</p>
            )}
          </>
        )}

        <hr />
        <h6 className="f-w-600 m-b-10 m-t-30">구독 크레딧 요금제</h6>
        {!subscription ? (
          <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
        ) : (
          <>
            {subscription?.creditPlan ? (
              <Row className="details">
                <Col xs="6" className={'detail-item'}>
                  요금제 ID
                  <br />
                  <span>{subscription.creditPlan?.id}</span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  요금제명
                  <br />
                  <span>{subscription.creditPlan?.title?.textKoKr}</span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  구독 크레딧
                  <br />
                  <span>
                    {subscription.creditPlan?.credit} <small>CREDIT</small>
                  </span>
                </Col>
                <Col xs="6" className={'detail-item'}>
                  할인률
                  <br />
                  <span>{subscription.creditPlan?.discountRate * 100}%</span>
                </Col>
              </Row>
            ) : (
              <p className={'text-center m-t-15'}>구독 중인 요금제가 없습니다.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(SubscriptionInfo);
