// Login API
// src/api/authApi.ts
import APIClient from './APIClient';
export const postLogin = async (credentials: { email: string; password: string }) => {
  const response = await APIClient.post('/v2/api/account/login', credentials);
  return response.data;
};

export const getMe = async () => {
  const response = await APIClient.get(`/v2/api/me/auth`);
  return response.data;
};
