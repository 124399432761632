import React from 'react';

export const taskColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    minWidth: '100px',
    cell: (row: any) => <div className={'text-left'}>ID.{row.id}</div>,
  },
  {
    name: '상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.status === 'REQUEST' && <span className={'btn btn-xs btn-info'}>신청 완료</span>}
        {row.status === 'PROCEEDING' && <span className={'btn btn-xs btn-success'}>진행중</span>}
        {row.status === 'COMPLETED' && <span className={'btn btn-xs btn-warning'}>작업 완료</span>}
        {row.status === 'CANCELED' && <span className={'btn btn-xs btn-danger'}>작업 취소</span>}
      </div>
    ),
  },
  {
    name: '작업 내용',
    selector: (row: any) => row.title,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '250px',
  },
  {
    name: '신청 크레딧',
    selector: (row: any) => row.credit,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: '차감 크레딧',
    selector: (row: any) => row.finalCredit,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: '거래일',
    selector: (row: any) => row.createdDate,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '160px',
  },
];
