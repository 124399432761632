import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Card, CardBody, Col, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import { getBrandInfo } from '../../api/brandAPI';

interface PropsType {
  idBrand?: string; // idBrand의 타입을 명확하게 설정 (string)
  refetchBrandInfo?: boolean;
}

const ManageBrand: React.FC<PropsType> = ({ idBrand, refetchBrandInfo }) => {
  // 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(['defaultData', idBrand], () => getBrandInfo(idBrand as string), {
    enabled: !!idBrand,
    onError: (error) => {
      useApiError(error);
    },
  });

  useEffect(() => {
    if (idBrand) {
      refetch();
    }
  }, [refetchBrandInfo]);

  return (
    <Fragment>
      <Card>
        <div className="card-header">
          <h5>브랜드 정보</h5>
        </div>
        <CardBody>
          <Row>
            <Col xs="6" className={'detail-item'}>
              브랜드 이름
              <br />
              <span>{defaultData?.name}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              기업명
              <br />
              <span>{defaultData?.businessName ? defaultData?.businessName : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              대표자
              <br />
              <span>{defaultData?.representative ? defaultData?.representative : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              이메일
              <br />
              <span>{defaultData?.eamil ? defaultData?.eamil : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              보유 크레딧
              <br />
              <span>{defaultData?.credit ? defaultData?.credit : '0'} Credit</span>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default ManageBrand;
