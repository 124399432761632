import React from 'react';

// WorkFlow
import Workflow from '../pages/workflow/list';
import ReadWorkflow from '../pages/workflow/read';
import CreateWorkflow from '../pages/workflow/update';
// Credit
import ListCredit from '../pages/credit/list';
import UpdateCredit from '../pages/credit/update';
// Task
import ListTask from '../pages/task/list';
import UpdateTask from '../pages/task/update';
import CreateTask from '../pages/task/create';
import ProductCategoryTask from '../pages/task/product/category/list';
import UpdateProductCategoryTask from '../pages/task/product/category/update';
import ProductTask from '../pages/task/product/list';
import UpdateProductTask from '../pages/task/product/update';
// Industry
import Industry from '../pages/industry/list';
import UpdateIndustry from '../pages/industry/update';
import CategoryIndustry from '../pages/industry/category/list';
import UpdateCategoryIndustry from '../pages/industry/category/update';
// Keyword
import Keyword from '../pages/keyword/list';
import UpdateKeyword from '../pages/keyword/update';
// Settings
import Settings from '../pages/setting/index';

export const routes = [
  { path: `/admin`, Component: <Workflow /> },
  // WorkFlow
  { path: `/admin/workflow/list`, Component: <Workflow /> },
  { path: `/admin/workflow/update`, Component: <CreateWorkflow /> },
  { path: `/admin/workflow/update/:id`, Component: <CreateWorkflow /> },
  { path: `/admin/workflow/read/:id`, Component: <ReadWorkflow /> },
  // Credit
  { path: `/admin/credit/list`, Component: <ListCredit /> },
  { path: `/admin/credit/update/:id`, Component: <UpdateCredit /> },
  // Task
  { path: `/admin/task/list`, Component: <ListTask /> },
  { path: `/admin/task/create`, Component: <CreateTask /> },
  { path: `/admin/task/update/:id`, Component: <UpdateTask /> },
  { path: `/admin/task/product/list`, Component: <ProductTask /> },
  { path: `/admin/task/product/update`, Component: <UpdateProductTask /> },
  { path: `/admin/task/product/update/:id`, Component: <UpdateProductTask /> },
  { path: `/admin/task/product/category/list`, Component: <ProductCategoryTask /> },
  { path: `/admin/task/product/category/update`, Component: <UpdateProductCategoryTask /> },
  { path: `/admin/task/product/category/update/:id`, Component: <UpdateProductCategoryTask /> },
  // Industry
  { path: `/admin/industry/list`, Component: <Industry /> },
  { path: `/admin/industry/update`, Component: <UpdateIndustry /> },
  { path: `/admin/industry/update/:id`, Component: <UpdateIndustry /> },
  // Industry - Category
  { path: `/admin/industry/category/list`, Component: <CategoryIndustry /> },
  { path: `/admin/industry/category/update`, Component: <UpdateCategoryIndustry /> },
  { path: `/admin/industry/category/update/:id`, Component: <UpdateCategoryIndustry /> },
  // Keyword
  { path: `/admin/keyword/list`, Component: <Keyword /> },
  { path: `/admin/keyword/update`, Component: <UpdateKeyword /> },
  { path: `/admin/keyword/update/:id`, Component: <UpdateKeyword /> },
  // Settings
  { path: `/admin/setting`, Component: <Settings /> },
];
