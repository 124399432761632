import React, { useCallback, useEffect, useState } from 'react';
import TbSearch from '../../../components/common/ui/TbSearch';
import { useMutation, useQuery } from 'react-query';
import { getBrandPage } from '../../../api/brandAPI';
import useApiError from '../../../hooks/useApiError';
import { debounce } from 'lodash';
import { Button } from 'reactstrap';
import { patchWorkflowConnect } from '../../../api/workflowAPI';

interface WorkflowSearchProps {
  onComplete?: () => void;
  label?: string;
  id?: string;
  name?: string;
  register?: any;
  errors?: any;
  validation?: any;
  idWorkflow: number;
}

const WorkflowSearch: React.FC<WorkflowSearchProps> = ({
  onComplete,
  label,
  id,
  name,
  register,
  errors,
  validation,
  idWorkflow,
}) => {
  // const navigate = useNavigate();
  const [query, setQuery] = useState<string | null>(null);
  const [idBrand, setIdBrand] = useState<number | null>(null);

  // GET ::: Brand ID
  const { data: searchResults, refetch } = useQuery(
    ['brandPage', query],
    () =>
      getBrandPage({
        query,
        page: 0,
        size: 30,
        market: false,
      }),
    {
      enabled: !!query && query.length < 1,
      select: (data) =>
        data?._embedded?.brandAdminResBodies.map((item: any) => ({
          value: item.id,
          label: `${item.name} (${item.email})`,
        })),
      onError: (error) => {
        useApiError(error);
      },
    },
  );

  useEffect(() => {
    if (query && query.length > 1) {
      refetch();
    }
  }, [query]);

  // Search
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value) {
        setQuery(value);
      }
    }, 500),
    [],
  );

  // onChange
  const handleSearchKeyword = (value: string) => {
    debouncedSearch(value);
  };

  /**
   * 브랜드 연결 요청.
   * */
  const patchWorkflowMutation = useMutation(patchWorkflowConnect, {
    onSuccess: () => {
      if (onComplete) {
        onComplete();
      }
    },
    onError: (error) => {
      useApiError(error);
    },
  });
  const connectBrand = () => {
    if (idWorkflow && idBrand) {
      patchWorkflowMutation.mutateAsync({
        idWorkflow,
        idBrand,
      });
    }
  };

  return (
    <>
      <TbSearch
        label={label}
        id={id}
        name={name}
        placeholder="ex) Tidy-B"
        register={register}
        errors={errors}
        validation={validation}
        onChange={handleSearchKeyword}
        searchData={searchResults}
        onSelectValue={setIdBrand}
      />
      <div className={'text-center'}>
        <Button onClick={connectBrand} color={'primary'}>
          브랜드 연결하기
        </Button>
      </div>
    </>
  );
};

export default WorkflowSearch;
