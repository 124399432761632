import React from 'react';
import { FormGroup } from 'reactstrap';

interface InputProps {
  label?: string;
  id?: string;
  name: string;
  type: string;
  placeholder?: string;
  disabled?: boolean;
  register: any;
  errors: any;
  validation?: any;
  defaultValue?: string | number | readonly string[] | undefined; // 입력 필드의 placeholder (선택 사항)
}

const TbInput: React.FC<InputProps> = ({
  label = '',
  name = '',
  id = '',
  disabled = false,
  type = 'text',
  placeholder = '',
  defaultValue = undefined,
  register = undefined,
  errors = {},
  validation = undefined,
}) => {
  return (
    <FormGroup className="mb-3">
      {label && (
        <label>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </label>
      )}
      <input
        className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue || ''}
        {...register(name, validation)}
        autoComplete="true"
        disabled={disabled}
      />
      {errors[name] && (
        <div className="text-danger mt-1">
          {errors[name]?.message ? errors[name].message : `${label}을/를 입력해주세요.`}
        </div>
      )}
    </FormGroup>
  );
};

export default React.memo(TbInput);
