import React from 'react';
import { FormGroup, Label } from 'reactstrap';

interface TbTextareaProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  cols?: number;
  register: any; // The register function from react-hook-form
  errors: any; // The errors object from react-hook-form
  validation?: any; // Validation rules for react-hook-form
}

const TbTextarea: React.FC<TbTextareaProps> = ({
  label = '',
  id = '',
  name = '',
  placeholder = '',
  disabled,
  rows = 5,
  cols = 5,
  register = undefined,
  errors = {},
  validation = undefined,
}) => {
  return (
    <FormGroup>
      <Label
        for={id}
        dangerouslySetInnerHTML={{
          __html: `${label} ${validation?.required ? '<span class="text-danger">(필수)</span>' : ''}`,
        }}
      ></Label>
      <textarea
        className="form-control"
        id={id}
        name={name || id}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name, validation)}
      ></textarea>

      {errors[name] && (
        <div className="text-danger mt-1">
          {errors[name]?.message ? errors[name].message : `${label}을/를 입력해주세요.`}
        </div>
      )}
    </FormGroup>
  );
};

export default React.memo(TbTextarea);
