import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../../hooks/useApiError';
import TbInput from '../../../components/common/ui/TbInput';
import TbModal from '../../../components/common/ui/TbModal';
import TbLoading from '../../../components/common/ui/TbLoading';
import Breadcrumb from '../../../components/common/breadcrumb';
import TbLangTab from '../../../components/common/ui/TbLangTab';
import TbRadio from '../../../components/common/ui/TbRadio';
import TbSelect from '../../../components/common/ui/TbSelect';
import TbTextarea from '../../../components/common/ui/TbTextarea';
import {
  deleteTaskProduct,
  getTaskProduct,
  getTaskProductCategoryPage,
  patchTaskProduct,
  postTaskProduct,
} from '../../../api/taskAPI';
import { toast } from 'react-toastify';

interface IndustryFormData {
  textKoKr?: string;
  textEnUs?: string;
  textZhCn?: string;
  textZhTw?: string;
  textJaJp?: string;
  keywords?: string;
  idCategory?: string;
  level?: string;
  credit?: number;
  koKr?: string;
  enUs?: string;
  zhCn?: string;
  zhTw?: string;
  jaJp?: string;
  active?: string;
}

const level = [
  { label: 'LV1. 라이트', value: 'LV1' },
  { label: 'LV2. 스탠다드', value: 'LV2' },
  { label: 'LV3. 디럭스', value: 'LV3' },
  { label: 'LV4. 프리미엄', value: 'LV4' },
  { label: 'LV5. 프리미엄 플러스', value: 'LV5' },
];

const options = [
  { label: '활성', value: 'true' },
  { label: '비활성', value: 'false' },
];

const TaskProduct: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idBrand = queryParams.get('idBrand');
  const query = queryParams.get('query');
  const status = queryParams.get('status');
  //
  const { id: idCategory } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [pageInfoData, setPageInfoData] = useState([]);
  const [reqParam] = useState({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 10,
    sort: '',
  });

  // 산업군
  const { isLoading } = useQuery(
    ['getTaskProductCategoryPage', { reqParam }],
    () => getTaskProductCategoryPage(reqParam),
    {
      onSuccess: (data) => {
        setPageInfoData(
          data?._embedded?.categories.map(
            (planInfo: any) =>
              ({
                label: planInfo.name,
                value: Number(planInfo.id),
              } || []),
          ),
        );
      },
      onError: (error) => {
        useApiError(error);
      },
    },
  );

  // Date states
  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IndustryFormData>({
    defaultValues: {
      active: 'true', // 여기에 초기값 설정
      koKr: 'true',
      enUs: 'true',
      zhCn: 'true',
      zhTw: 'true',
      jaJp: 'true',
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(['defaultData', idCategory], () => getTaskProduct(idCategory as string), {
    enabled: !!idCategory,
    onError: (error) => {
      useApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // 클라이언트
      setValue('textKoKr', defaultData.content?.textKoKr);
      setValue('textEnUs', defaultData.content?.textEnUs);
      setValue('textZhCn', defaultData.content?.textZhCn);
      setValue('textZhTw', defaultData.content?.textZhTw);
      setValue('textJaJp', defaultData.content?.textJaJp);
      setValue('idCategory', defaultData?.idCategory);
      setValue('level', defaultData?.level);
      setValue('credit', defaultData?.credit);
      setValue('koKr', String(defaultData.internationalResBody ? defaultData.internationalResBody?.koKr : true));
      setValue('enUs', String(defaultData.internationalResBody ? defaultData.internationalResBody?.enUs : false));
      setValue('zhCn', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhCn : false));
      setValue('zhTw', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhTw : false));
      setValue('jaJp', String(defaultData.internationalResBody ? defaultData.internationalResBody?.jaJp : false));
      setValue('active', String(defaultData.active));
    }
  }, [defaultData, pageInfoData]);

  // API  Post ::: Create
  const createIndustryMutation = useMutation(postTaskProduct, {
    onSuccess: (data) => {
      console.debug('Workflow created successfully:', data);
      navigate(`/admin/task/product/list`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // API Patch ::: Update
  const patchIndustryMutation = useMutation(({ id, data }: { id: string; data: any }) => patchTaskProduct(id, data), {
    onSuccess: (data) => {
      console.debug('Category updated successfully:', data);
      navigate(`/admin/task/product/list`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    console.debug('data:::::', data);
    const requestData = {
      content: {
        textKoKr: data.textKoKr,
        textEnUs: data.textEnUs,
        textZhCn: data.textZhCn,
        textZhTw: data.textZhTw,
        textJaJp: data.textJaJp,
      },
      idCategory: Number(data.idCategory),
      credit: data.credit,
      level: data.level,
      internationalReqBody: {
        koKr: data.koKr === 'true',
        enUs: data.enUs === 'true',
        zhCn: data.zhCn === 'true',
        zhTw: data.zhTw === 'true',
        jaJp: data.jaJp === 'true',
      },
      active: data.active === 'true',
    };
    try {
      if (idCategory && Number(idCategory) > 0) {
        await patchIndustryMutation.mutateAsync({
          id: String(idCategory), // `id`를 문자열로 변환
          data: requestData, // 업데이트할 데이터
        });
      } else {
        await createIndustryMutation.mutateAsync(requestData);
      }
      toast.success('작업이 성공적으로 완료되었습니다.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      });
    } catch (error) {
      useApiError(error);
    }
  };

  // 삭제 요청을 위한 뮤테이션 훅 설정
  const deleteMutation = useMutation(deleteTaskProduct, {
    onSuccess: () => {
      console.debug('삭제 성공:::: ');
      navigate(`/admin/task/product/list`);
      toggleModal();
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 삭제 핸들러
  const handleDelete = () => {
    if (idCategory) deleteMutation.mutate(idCategory);
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  const handleRowClicked = () => {
    const params = new URLSearchParams();

    if (idBrand) params.set('idBrand', idBrand);
    if (query) params.set('query', query);
    if (status) params.set('status', status);

    navigate(`/admin/task/product/list?${params.toString()}`);
  };
  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h4 className="text-center m-0">삭제 하시겠습니까?</h4>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="작업" title={idCategory ? '수정' : '생성'}>
          <div className="mb-0">
            {idCategory ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idCategory ? '수정' : '생성'}
            </Button>
            <Button color="primary" onClick={handleRowClicked}>
              목록
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <TbLangTab title={'디자이너 작업'} errors={errors}>
                <div>
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'textKoKr'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="koKr" register={register} />
                </div>
                <div>
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'textEnUs'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="enUs" register={register} />
                </div>
                <div>
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'textZhCn'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhCn" register={register} />
                </div>
                <div>
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'textZhTw'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhTw" register={register} />
                </div>
                <div>
                  <TbTextarea
                    label="설명"
                    id="memo"
                    name={'textJaJp'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="jaJp" register={register} />
                </div>
              </TbLangTab>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>상품 설정</h5>
                </div>
                <CardBody>
                  <TbSelect
                    label="카테고리"
                    name="idCategory"
                    options={pageInfoData ? pageInfoData : []}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbSelect
                    label="상품 등급"
                    name="level"
                    options={level ? level : []}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="크레딧 가격"
                    name={'credit'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                </CardBody>
              </Card>
              <Card>
                <div className="card-header">
                  <h5>설정</h5>
                </div>
                <CardBody>
                  <TbRadio title={'게시 상태'} options={options} name="active" register={register} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default TaskProduct;
