import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbModal from '../../components/common/ui/TbModal';
import { cancelCredit, getCredit, refundCredit } from '../../api/creditAPI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreditUpdate: React.FC = () => {
  // idBrand
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idBrand = queryParams.get('idBrand');
  const query = queryParams.get('query');
  const status = queryParams.get('status');
  // 크레딧 아이디
  const { id: idCredit } = useParams<{ id: string }>();
  const navigate = useNavigate();
  // Modal
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(['defaultData', idCredit], () => getCredit(idCredit as string), {
    enabled: !!idCredit,
    onError: (error) => {
      useApiError(error);
    },
  });

  // 크레딧 사용 취소
  const cancelCreditMutation = useMutation((idCredit: string) => cancelCredit(idCredit), {
    onSuccess: (data) => {
      console.debug('Credit cancel successfully:', data);
      toggleCancelModal();
      navigate(`/admin/credit/list?idBrand=${idBrand}`);
    },
    onError: (error: any) => {
      if (error?.response?.data?.message === 'ALREADY_CANCEL_CREDIT') {
        toast.warn('이미 취소된 크레딧입니다.', { position: toast.POSITION.TOP_CENTER });
      } else {
        // Handle other errors
        useApiError(error);
      }
    },
  });

  // 크레딧 환불
  const refundCreditMutation = useMutation((idCredit: string) => refundCredit(idCredit), {
    onSuccess: (data) => {
      console.debug('Credit refund successfully:', data);
      toggleRefundModal();
      navigate(`/admin/credit/list?idBrand=${idBrand}`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 핸들러: 사용 취소
  const handleCancel = () => {
    if (idCredit) {
      cancelCreditMutation.mutate(idCredit);
    } else {
      console.error('idCredit is undefined');
    }
  };

  // 핸들러: 환불
  const handleRefund = () => {
    if (idCredit) {
      refundCreditMutation.mutate(idCredit);
    } else {
      console.error('idCredit is undefined');
    }
  };

  // Date states
  // Form
  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const toggleRefundModal = () => {
    setIsRefundModalOpen(!isRefundModalOpen);
  };

  const handleRowClicked = () => {
    const params = new URLSearchParams();

    if (idBrand) params.set('idBrand', idBrand);
    if (query) params.set('query', query);
    if (status) params.set('status', status);

    navigate(`/admin/credit/list?${params.toString()}`);
  };

  return (
    <Fragment>
      {/* :::::::: 크레딧 사용 취소 : Start ::::::: */}
      <TbModal
        isOpen={isCancelModalOpen}
        toggle={toggleCancelModal}
        onClickConfirm={handleCancel}
        isBtnCancel={true}
        isShowFooter
      >
        <h4 className="text-center m-0">크레딧 사용을 취소 하시겠습니까?</h4>
      </TbModal>
      {/* :::::::: 크레딧 사용 취소 : END ::::::: */}
      {/* :::::::: 크레딧 환불 : Start ::::::: */}
      <TbModal
        isOpen={isRefundModalOpen}
        toggle={toggleRefundModal}
        onClickConfirm={() => handleRefund}
        isBtnCancel={true}
        isShowFooter
      >
        <h4 className="text-center m-0">크레딧을 환불 하시겠습니까?</h4>
      </TbModal>
      {/* :::::::: 크레딧 환불 : END ::::::: */}
      <Breadcrumb parent="크레딧" title="크레딧 설정">
        <div className="mb-0">
          {/* :::::::::: 취소 :::::::: */}
          {defaultData?.status === 'USE' && (
            <Button color="danger" className="me-3" onClick={toggleCancelModal}>
              크레딧 사용 취소
            </Button>
          )}
          {/* :::::::::: 환불 :::::::: */}
          {defaultData?.refund && (
            <Button color="danger" className="me-3" type="submit" onClick={toggleRefundModal}>
              크레딧 환불
            </Button>
          )}
          {/*<Link to={`/admin/credit/list?query=${query}&idBrand=${idBrand}`}>*/}
          <Button color="primary" onClick={handleRowClicked}>
            목록
          </Button>
          {/*</Link>*/}
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="4">
            <Card>
              <div className="card-header">
                <h5>크레딧 정보</h5>
              </div>
              <CardBody>
                <Row>
                  <Col xs="6" className={'detail-item'}>
                    상태
                    <br />
                    {defaultData?.status && (
                      <>
                        {defaultData.status === 'ACCUMULATE' && (
                          <span className={'btn btn-xs btn-info text-white'}>적립</span>
                        )}
                        {defaultData.status === 'USE' && (
                          <span className={'btn btn-xs btn-success text-white'}>사용</span>
                        )}
                        {defaultData.status === 'EXPIRE' && (
                          <span className={'btn btn-xs btn-danger text-white'}>만료</span>
                        )}
                        {defaultData.status === 'REFUND' && (
                          <span className={'btn btn-xs btn-warning text-white'}>환불</span>
                        )}
                        {defaultData.status === 'CANCEL' && (
                          <span className={'btn btn-xs btn-warning text-white'}>취소</span>
                        )}
                      </>
                    )}
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    금액
                    <br />
                    <span>{defaultData?.amount ? defaultData?.amount : '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    거래일
                    <br />
                    <span>{defaultData?.expiredDate ? defaultData?.expiredDate : '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    만료일 <br />
                    <span>{defaultData?.createdDate ? defaultData?.createdDate : '-'}</span>
                  </Col>
                  <Col xs="12" className={'detail-item'}>
                    설명
                    <br />
                    <span>{defaultData?.description ? defaultData?.description : '-'}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CreditUpdate;
