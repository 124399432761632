// src/routes/index.js
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'react-feather';
import PrivateRoute from './private-route';
import TbGlobalModal from '../components/common/ui/TbGlobalModal';
import { authRoutes } from './auth-routs';
import { routes } from './layouts-routes';
import { useSelector } from 'react-redux';
import Error404 from '../pages/errors/error404';

const MainRoutes = () => {
  const { isUserData } = useSelector((state) => state.user);

  return (
    <BrowserRouter basename="">
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* ::::: 인증 완료시(isAuthenticated = true) 디폴트 페이지로 이동 :::::  */}
          {isUserData ? (
            <>
              <Route exact path={`/login`} element={<Navigate to={`/admin/workflow/list`} />} />
              <Route exact path={`/`} element={<Navigate to={`/admin/workflow/list`} />} />
            </>
          ) : (
            <Route exact path={``} element={<Navigate to={`/admin/login`} />} />
          )}

          {/*  ::::: 전체 Dashboard 페이지 :::::  */}
          {routes.map(({ path, Component }, i) => (
            <Route path={path} element={<PrivateRoute />} key={i}>
              <Route exact path={path} element={Component} />
            </Route>
          ))}

          {/*  ::::: Dashboard 페이지를 제외한 url 로 접근가능한 페이지(인증 관련) :::::  */}
          {authRoutes.map(({ path, Component }, i) => (
            <Route key={i} path={path} element={Component} />
          ))}

          {/* ::::: 잘못된 url 접근시 400 페이지로 이동 ::::: */}
          <Route path="*" element={<Error404 />} />
        </Routes>

        {/* ::::: 공통 모달 :::::   */}
        <TbGlobalModal />
      </Suspense>
    </BrowserRouter>
  );
};

export default MainRoutes;
