import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { getPaymentList, ReqPaymentList } from '../../../api/paymentAPI';
import { paymentColumns } from '../data/payment-columns';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface PropsType {
  idSubscription?: number;
}

// TODO 결제링크 생성기능 => 모달로 처리
// TODO (구독 상세 페이지 공통으로 사용됨)
function ManagePayment({ idSubscription }: PropsType) {
  const [dropdownOpen, setOpen] = useState(false);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    page: 0,
    size: 5,
    sort: '',
  });

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getPaymentList', { reqParams }],
    () => getPaymentList({ ...reqParams, idSubscription }),
    {
      enabled: !!idSubscription,
      onSuccess: (data) => {
        setListData(data?._embedded?.payments || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        useApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 아이템 클릭
   * */
  const handleRowClicked = (row: any) => {
    // navigate(`/admin/payment/view/${row.id}`);
    // TODO 해당 페이지 개발 후, 내부 라우트로 변경 처리해야함.
    if (window) {
      window.open(`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admin/payment/view/${row.id}`);
    }
  };

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  // TODO 결제링크 생성
  // const handlePayLink = () => {
  //
  // };
  return (
    <div className="card">
      <div className={'card-header'}>
        <h5>결제 관리</h5>
        {idSubscription && (
          <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
            <DropdownToggle size={'sm'} color="dark" outline={true}>
              <span>
                작업 <i className="fa fa-caret-down"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                // TODO 해당 페이지 개발 후, 내부 라우트로 변경 처리해야함.
                href={`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admin/payment?idSubscription=${idSubscription}`}
                target={'_blank'}
              >
                {'전체 내역 조회'}
              </DropdownItem>
              <DropdownItem
                // TODO 해당 페이지 개발 후, 내부 라우트로 변경 처리해야함.
                href={`${process.env.REACT_APP_API_ADMIN_BASE_URL}/admin/subscription/view/${idSubscription}`}
                target={'_blank'}
              >
                {'결제 링크 생성'}
              </DropdownItem>
              {/*<DropdownItem onClick={() => handlePayLink()}>{'결제 링크 생성'}</DropdownItem>*/}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      {!idSubscription ? (
        <div className={'card-body'}>
          <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
        </div>
      ) : (
        <div className={'card-body card-table-body'}>
          <DataTable
            noHeader
            columns={paymentColumns}
            data={listData}
            progressPending={isLoading}
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleRowClicked}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(ManagePayment);
