import React from 'react';
import { Col, Row } from 'reactstrap';
import { useQuery } from 'react-query';
import { getLogoQuestDetail } from '../../../api/workflowAPI';
import useApiError from '../../../hooks/useApiError';

// props로 logoQuestInfo 받아오기

interface PropsType {
  idBrand?: number;
}

const ManageLogoQuest: React.FC<PropsType> = ({ idBrand }: PropsType) => {
  const { data: logoQuestDetail } = useQuery(
    ['logoQuestDetail', idBrand],
    () => getLogoQuestDetail(idBrand as number),
    {
      enabled: !!idBrand,
      onError: (error) => {
        useApiError(error);
      },
    },
  );

  return (
    <div className="card">
      <div className={'card-header'}>
        <h5>로고 퀘스트 정보</h5>
      </div>
      <div className={'card-body'}>
        {!idBrand && <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>}
        {idBrand && !logoQuestDetail && <p className={`text-center m-5-15 text-danger`}>로고 퀘스트를 진행해주세요.</p>}
        {logoQuestDetail && (
          <Row className="details">
            <Col xs="6" className={'detail-item'}>
              브랜드명
              <br />
              <span>{logoQuestDetail.name}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              슬로건
              <br />
              <span>{logoQuestDetail.slogan ? logoQuestDetail.slogan : '-'}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              산업군
              <br />
              <span>
                {logoQuestDetail?.industry
                  ? `${logoQuestDetail?.industry?.name} - ${logoQuestDetail?.industry?.industryCategory?.name}`
                  : `기타 - ${logoQuestDetail?.etcIndustry}`}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              색상
              <br />
              <span>{logoQuestDetail.logoColor}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              글꼴 스타일
              <br />
              <span>{logoQuestDetail.fontStyle}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              로고 스타일
              <br />
              <span>{logoQuestDetail.logoStyle}</span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              키워드
              <br />
              <span>
                {logoQuestDetail.keywords[0]},&nbsp;
                {logoQuestDetail.keywords[1]},&nbsp;
                {logoQuestDetail.keywords[2]},&nbsp;
                {logoQuestDetail.keywords[3]},&nbsp;
                {logoQuestDetail.keywords[4]}
              </span>
            </Col>
            <Col xs="6" className={'detail-item'}>
              희망사항
              <br />
              <span>{logoQuestDetail.wishlist ? logoQuestDetail.wishlist : '-'}</span>
            </Col>
            <Col xs="12" className={'detail-item'}>
              첨부 파일
              <br />
              {!logoQuestDetail.imageUrl1 && !logoQuestDetail.imageUrl2 && !logoQuestDetail.imageUrl3 && '-'}
              {['imageUrl1', 'imageUrl2', 'imageUrl3'].map((key, index) =>
                logoQuestDetail?.[key] ? (
                  <div key={key}>
                    <a href={logoQuestDetail[key]} target="_blank" rel="noreferrer">
                      첨부 파일 {index + 1}
                    </a>
                  </div>
                ) : null,
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default React.memo(ManageLogoQuest);
