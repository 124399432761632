import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';

export interface TbSelectItem {
  label?: string;
  value?: string | number;
}
interface TbSelectProps {
  label?: string;
  row?: boolean;
  id?: string;
  name: string;
  placeholder?: string;
  options: TbSelectItem[];
  register: any;
  validation?: any;
  errors?: any;
}

const TbSelect: React.FC<TbSelectProps> = ({
  placeholder = '',
  row = false,
  name = '',
  label = '',
  id = '',
  options = [],
  register = undefined,
  validation = undefined,
  errors = {},
}) => {
  return (
    <FormGroup>
      {row ? (
        ''
      ) : (
        <Label>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </Label>
      )}
      <div className="position-relative">
        <SelectLayout
          className="form-control digits position-relative"
          defaultValue=""
          id={id}
          name={name}
          {...register(name, validation)}
        >
          <option value={''}>{placeholder ? placeholder : '(선택 안함)'}</option>

          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectLayout>
        <SelectIcon className="icon-angle-down" />
      </div>
      {errors && errors[name] && (
        <div className="text-danger mt-1">{errors[name].message || `${label}을/를 선택해주세요.`}</div>
      )}
    </FormGroup>
  );
};

export default React.memo(TbSelect);

const SelectIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 0;
`;

const SelectLayout = styled.select`
  &:disabled {
    color: #ced4da;
  }
`;
