import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../store/reducers/modalSlice';

const TbGlobalModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isOpen, message, headerTitle, showHeader, showFooter } = useSelector((state: any) => state.modal);

  const toggle = () => {
    dispatch(closeModal());
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} size="md">
      {showHeader && <ModalHeader toggle={toggle}>{headerTitle}</ModalHeader>}
      <ModalBody className={'py-5'}>
        <h3 className={'text-center m-0'}>{message}</h3>
      </ModalBody>
      {showFooter && (
        <ModalFooter>
          <Button color="primary me-3" onClick={toggle}>
            확인
          </Button>
          {/*<Button color="danger" onClick={toggle}>*/}
          {/*  취소*/}
          {/*</Button>*/}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default React.memo(TbGlobalModal);
