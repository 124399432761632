import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TbInput from '../../components/common/ui/TbInput';
import TbSelect from '../../components/common/ui/TbSelect';
import TbTextarea from '../../components/common/ui/TbTextarea';
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflowDetail,
  getWorkflowEnum,
  patchWorkflow,
  ReqWorkflow,
} from '../../api/workflowAPI';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import TbModal from '../../components/common/ui/TbModal';
import { getPlanList, ResPlan } from '../../api/planAPI';

interface WorkflowFormData {
  id?: string;
  brandName?: string;
  businessName?: string;
  userName?: string;
  phoneNumber?: string;
  memo?: string;
  manager?: string;
  priority?: string;
  price?: string;
  onBoarding?: string;
  design?: string;
  platform?: string;
  startDate?: string;
  endDate?: string;
  idBrand?: string;
  idPlan?: string;
}

const CreateWorkflow: React.FC = () => {
  const { id: idWorkflow } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Date states
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<WorkflowFormData>();

  // 요금제 목록 로드
  const { data: planList } = useQuery(['getPlanList'], () => getPlanList({ idActive: undefined }), {
    select: (data: ResPlan[]) =>
      data.map((planInfo) => ({
        label: planInfo.title + ` (ID.${planInfo.id}, Lv.${planInfo.level})`,
        value: Number(planInfo.id),
      })),
    onError: (error) => {
      useApiError(error);
    },
  });
  // 초기 데이터 로드
  const { data: defaultData } = useQuery(['defaultData', idWorkflow], () => getWorkflowDetail(idWorkflow as string), {
    enabled: !!planList && !!idWorkflow,
    onError: (error) => {
      useApiError(error);
    },
  });

  // Fetch Enum Data ::: Select 데이터 가져오기
  const formatList = (list: Record<string, string>) => {
    return Object.entries(list).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  };

  // 타입 리스트 로든
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    select: (data) => ({
      onBoardingList: formatList(data.onBoardingList),
      designList: formatList(data.designList),
      platformList: formatList(data.platformList),
      managerList: formatList(data.managerList),
      priorityList: formatList(data.priorityList),
    }),
    onError: (error) => {
      useApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // 클라이언트
      setValue('brandName', defaultData.brandName);
      setValue('businessName', defaultData.businessName);
      setValue('userName', defaultData.userName);
      setValue('phoneNumber', defaultData.phoneNumber);
      setValue('memo', defaultData.memo);
      // 계약정보
      setValue('manager', defaultData.manager);
      setValue('priority', defaultData.priority);
      setValue('price', defaultData.price);
      setValue('onBoarding', defaultData.onBoarding);
      setValue('design', defaultData.design);
      setValue('platform', defaultData.platform);
      setValue('startDate', defaultData.startDate);
      setValue('endDate', defaultData.endDate);
      setValue('idPlan', defaultData.plan?.id);
    }
  }, [defaultData]);

  // API Post ::: Create
  const createWorkflowMutation = useMutation(createWorkflow, {
    onSuccess: (data) => {
      console.debug('Workflow created successfully:', data);
      navigate(`/admin/workflow/list`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // API Patch ::: Update
  const patchWorkflowMutation = useMutation(patchWorkflow, {
    onSuccess: (data) => {
      console.debug('Workflow created successfully:', data);
      navigate(`/admin/workflow/read/${idWorkflow}`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const reqBody = {
      ...data,
      onBoarding: data.onBoarding ? data.onBoarding : null,
      design: data.design ? data.design : null,
      platform: data.platform ? data.platform : null,
      manager: data.manager ? data.manager : null,
      priority: data.priority ? data.priority : null,
      startDate: startDate ? startDate.toISOString() : '',
      endDate: endDate ? endDate.toISOString() : '',
    } as ReqWorkflow;
    if (idWorkflow && Number(idWorkflow) > 0) {
      //Update
      try {
        await patchWorkflowMutation.mutateAsync({
          ...reqBody,
          id: Number(idWorkflow),
        });
      } catch (error) {
        useApiError(error);
      }
    } else {
      // Create
      try {
        await createWorkflowMutation.mutateAsync({
          ...reqBody,
        });
      } catch (error) {
        useApiError(error);
      }
    }

    setIsLoading(false);
  };

  // 삭제 요청을 위한 뮤테이션 훅 설정
  const deleteMutation = useMutation(deleteWorkflow, {
    onSuccess: () => {
      console.debug('삭제 성공:::: ');
      navigate(`/admin/workflow/list`);
      toggleModal();
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 삭제 핸들러
  const handleDelete = () => {
    if (idWorkflow) deleteMutation.mutate(idWorkflow);
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };
  // =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h4 className="text-center m-0">삭제 하시겠습니까?</h4>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="고객관리" title={idWorkflow ? '수정' : '생성'}>
          <div className="mb-0">
            {idWorkflow ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idWorkflow ? '수정' : '생성'}
            </Button>
            <Link to={`/admin/workflow/list`}>
              <Button color="primary">목록</Button>
            </Link>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <Card>
                <div className="card-header">
                  <h5>클라이언트</h5>
                </div>
                <CardBody>
                  <Col sm="12">
                    <TbInput
                      label="브랜드 이름"
                      name={'brandName'}
                      type="text"
                      register={register}
                      errors={errors}
                      validation={{ required: true }}
                    />
                  </Col>
                  <Col sm="12">
                    <TbInput label="기업명" name={'businessName'} type="text" register={register} errors={errors} />
                  </Col>
                  <Col sm="12">
                    <TbInput label="담당자 이름" name={'userName'} type="text" register={register} errors={errors} />
                  </Col>
                  <Col sm="12">
                    <TbInput
                      label="연락처"
                      name="phoneNumber"
                      type="text"
                      register={register}
                      errors={errors}
                      validation={{
                        pattern: {
                          value: /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
                          message: '유효한 전화번호를 입력하세요.',
                        },
                      }}
                    />
                  </Col>
                  <TbTextarea
                    label="메모"
                    id="memo"
                    name={'memo'}
                    placeholder="메모를 작성해 주세요."
                    rows={10}
                    register={register}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>계약 정보</h5>
                </div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <TbSelect
                        label="담당 매니저"
                        name="manager"
                        options={enumType?.managerList ? enumType.managerList : []}
                        register={register}
                        errors={errors}
                        validation={{ required: true }}
                      />
                    </Col>
                    <Col sm="12">
                      <TbSelect
                        label="우선순위"
                        name="priority"
                        options={enumType?.priorityList ? enumType.priorityList : []}
                        register={register}
                        errors={errors}
                        validation={{ required: true }}
                      />
                    </Col>
                    <Col sm="12">
                      <TbInput
                        label="견적(원)"
                        name={'price'}
                        type="number"
                        placeholder={'0'}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col sm="12">
                      <TbSelect
                        label="온보딩 상태"
                        name="onBoarding"
                        options={enumType?.onBoardingList ? enumType.onBoardingList : []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col sm="12">
                      <TbSelect
                        label="디자인 상태"
                        name="design"
                        options={enumType?.designList ? enumType.designList : []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col sm="12">
                      <TbSelect
                        label="플랫폼"
                        name="platform"
                        options={enumType?.platformList ? enumType.platformList : []}
                        register={register}
                        errors={errors}
                        validation={{ required: true }}
                      />
                    </Col>
                    <Col sm="12">
                      <TbSelect
                        label="요금제"
                        name="idPlan"
                        options={planList ? planList : []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                    <Col sm="12">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label>시작 일</Label>
                            <DatePicker
                              className="datepicker-here form-control"
                              selected={startDate}
                              onChange={(date: Date | null) => setStartDate(date)}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <Label>종료 일</Label>
                          <DatePicker
                            className="datepicker-here form-control"
                            selected={endDate}
                            onChange={(date: Date | null) => setEndDate(date)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default CreateWorkflow;
