import React from 'react';
import { Loader } from 'react-feather';
import styled from 'styled-components';

interface TbLoadingProps {
  fullPage?: boolean;
}

const TbLoading: React.FC<TbLoadingProps> = ({ fullPage = false }) => {
  return (
    <LoadingLayout fullPage={fullPage}>
      <Loader size={fullPage ? 40 : 20} />
    </LoadingLayout>
  );
};

export default React.memo(TbLoading);

const LoadingLayout = styled.div<{ fullPage: boolean }>`
  min-height: ${({ fullPage }) => (fullPage ? '100vh' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
`;
