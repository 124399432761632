import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../store/reducers/modalSlice';

const useApiError = (error: any): void => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  /* 일반적인 API 요청에 대한 Error 처리 */
  useEffect(() => {
    if (error) {
      // Error 메세지
      /* Error 처리
       * 400 번대 : 400, 401, 403, 404
       * 500 번대 : 500, 503
       * */
      console.debug('useApiError::::::', error);
      // if (erroror.response.status === 400) {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror400`);
      // } else if (erroror.status === 401) {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror401`);
      // } else if (erroror.status === 403) {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror403`);
      // } else if (erroror.status === 404) {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror404`);
      // } else if (erroror.status === 503) {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror503`);
      // } else {
      //   navigate(`${process.env.PUBLIC_URL}/errorors/erroror500`);
      // }
      // 에러 처리.
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        // 400번대 에러
        dispatch(openModal(error.response.data.message ? error.response.data.message : '잘못된 접근입니다.'));
      } else if (error.message) {
        // 로컬 에러
        dispatch(openModal(error.message ? error.message : '삭제가 완료 되었습니다.'));
      } else {
        // 그 외 서버에러
        dispatch(openModal(error.message ? error.message : 'SERVER ERROR : 관리자에게 문의하세요.'));
      }
    }
  }, [error]);
};

export default useApiError;
