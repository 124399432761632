import React from 'react';

interface RadioProps {
  label: string;
  name: string;
  id: string;
  value: string;
  checked?: boolean;
  register: any;
}

const Radio: React.FC<RadioProps> = ({ label, name, id, value, register = undefined }) => {
  return (
    <div>
      <label className="d-block" htmlFor={id}>
        <input className="radio_animated" type="radio" id={id} value={value} {...register(name)} />
        {label}
      </label>
    </div>
  );
};

interface RadioGroupProps {
  options: { label: string; value: string }[]; // 라벨과 값의 배열
  name: string;
  title: string;
  register: any;
}

const TbRadio: React.FC<RadioGroupProps> = ({ title, options, name, register = undefined }) => {
  return (
    <div>
      <div className={'mb-2'}>{title}</div>
      <div className={'d-flex'}>
        {options.map((option, index) => (
          <div key={index} style={{ marginLeft: index !== 0 ? '20px' : '0' }}>
            <Radio label={option.label} name={name} id={`${name}-${index}`} value={option.value} register={register} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TbRadio;
