import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  deleteIndustry,
  getCategoryIndustryList,
  getIndustry,
  patchIndustry,
  postIndustryList,
} from '../../api/industryAPI';
import useApiError from '../../hooks/useApiError';
import TbInput from '../../components/common/ui/TbInput';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLangTab from '../../components/common/ui/TbLangTab';
import TbRadio from '../../components/common/ui/TbRadio';
import TbSelect from '../../components/common/ui/TbSelect';
import TbTextarea from '../../components/common/ui/TbTextarea';

interface IndustryFormData {
  nameKoKr?: string;
  nameEnUs?: string;
  nameZhCn?: string;
  nameZhTw?: string;
  nameJaJp?: string;
  keywords?: string;
  idCategory?: string;
  koKr?: string;
  enUs?: string;
  zhCn?: string;
  zhTw?: string;
  jaJp?: string;
  active?: string;
}

const options = [
  { label: '활성', value: 'true' },
  { label: '비활성', value: 'false' },
];

const CreateWorkflow: React.FC = () => {
  const { id: idCategory } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  // 산업군
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const { isLoading } = useQuery(['getCategoryIndustryPage'], () => getCategoryIndustryList(), {
    onSuccess: (data) => {
      setPageInfoData(
        data.map(
          (planInfo: any) =>
            ({
              label: planInfo.nameKoKr,
              value: Number(planInfo.id),
            } || []),
        ),
      );
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // Date states
  // Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IndustryFormData>({
    defaultValues: {
      active: 'true', // 여기에 초기값 설정
      koKr: 'true',
      enUs: 'true',
      zhCn: 'true',
      zhTw: 'true',
      jaJp: 'true',
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(['defaultData', idCategory], () => getIndustry(idCategory as string), {
    enabled: !!idCategory,
    onError: (error) => {
      useApiError(error);
    },
  });

  useEffect(() => {
    if (defaultData) {
      console.debug('::: 갱신', defaultData);
      // 클라이언트
      setValue('nameKoKr', defaultData.nameKoKr);
      setValue('nameEnUs', defaultData.nameEnUs);
      setValue('nameZhCn', defaultData.nameZhCn);
      setValue('nameZhTw', defaultData.nameZhTw);
      setValue('nameJaJp', defaultData.nameJaJp);
      setValue('keywords', defaultData.keywords);
      setValue('idCategory', defaultData?.industryCategory?.id);
      setValue('koKr', String(defaultData.internationalResBody ? defaultData.internationalResBody?.koKr : true));
      setValue('enUs', String(defaultData.internationalResBody ? defaultData.internationalResBody?.enUs : false));
      setValue('zhCn', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhCn : false));
      setValue('zhTw', String(defaultData.internationalResBody ? defaultData.internationalResBody?.zhTw : false));
      setValue('jaJp', String(defaultData.internationalResBody ? defaultData.internationalResBody?.jaJp : false));
      setValue('active', String(defaultData.active));
    }
  }, [defaultData, pageInfoData]);

  // API Post ::: Create
  const createIndustryMutation = useMutation(postIndustryList, {
    onSuccess: (data) => {
      console.debug('Workflow created successfully:', data);
      navigate(`/admin/industry/list`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // API Patch ::: Update
  const patchIndustryMutation = useMutation(({ id, data }: { id: string; data: any }) => patchIndustry(id, data), {
    onSuccess: (data) => {
      console.debug('Category updated successfully:', data);
      navigate(`/admin/industry/list`);
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    console.debug('data:::::', data);
    const requestData = {
      nameKoKr: data.nameKoKr,
      nameEnUs: data.nameEnUs,
      nameZhCn: data.nameZhCn,
      nameZhTw: data.nameZhTw,
      nameJaJp: data.nameJaJp,
      idCategory: Number(data.idCategory),
      keywords: data.keywords,
      internationalReqBody: {
        koKr: data.koKr === 'true',
        enUs: data.enUs === 'true',
        zhCn: data.zhCn === 'true',
        zhTw: data.zhTw === 'true',
        jaJp: data.jaJp === 'true',
      },
      active: data.active === 'true',
    };
    if (idCategory && Number(idCategory) > 0) {
      // Update (PATCH)
      console.debug('requestData:::', requestData);
      try {
        await patchIndustryMutation.mutateAsync({
          id: String(idCategory), // `id`를 문자열로 변환
          data: requestData, // 업데이트할 데이터
        });
      } catch (error) {
        useApiError(error);
      }
    } else {
      // Create (POST)
      try {
        await createIndustryMutation.mutateAsync(requestData);
      } catch (error) {
        useApiError(error);
      }
    }
  };

  // 삭제 요청을 위한 뮤테이션 훅 설정
  const deleteMutation = useMutation(deleteIndustry, {
    onSuccess: () => {
      console.debug('삭제 성공:::: ');
      navigate(`/admin/industry/list`);
      toggleModal();
    },
    onError: (error) => {
      useApiError(error);
    },
  });

  // 삭제 핸들러
  const handleDelete = () => {
    if (idCategory) deleteMutation.mutate(idCategory);
  };

  const toggleModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };
  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleModal}>
        <h4 className="text-center m-0">삭제 하시겠습니까?</h4>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="산업군" title={idCategory ? '수정' : '생성'}>
          <div className="mb-0">
            {idCategory ? (
              <Button onClick={toggleModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idCategory ? '수정' : '생성'}
            </Button>
            <Link to={`/admin/industry/list`}>
              <Button color="primary">목록</Button>
            </Link>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <TbLangTab title={'산업군 설정'} errors={errors}>
                <div>
                  <TbInput
                    label="산업군 이름"
                    name={'nameKoKr'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="koKr" register={register} />
                </div>
                <div>
                  <TbInput
                    label="산업군 이름"
                    name={'nameEnUs'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="enUs" register={register} />
                </div>
                <div>
                  <TbInput
                    label="산업군 이름"
                    name={'nameZhCn'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhCn" register={register} />
                </div>
                <div>
                  <TbInput
                    label="산업군 이름"
                    name={'nameZhTw'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="zhTw" register={register} />
                </div>
                <div>
                  <TbInput
                    label="산업군 이름"
                    name={'nameJaJp'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadio title={'언어 활성 여부'} options={options} name="jaJp" register={register} />
                </div>
              </TbLangTab>
            </Col>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>설정</h5>
                </div>
                <CardBody>
                  <TbSelect
                    label="카테고리"
                    name="idCategory"
                    options={pageInfoData ? pageInfoData : []}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbTextarea
                    label="키워드"
                    id="memo"
                    name={'keywords'}
                    placeholder="검색 키워드를 작성해 주세요. ex) 패션, 옷,"
                    rows={5}
                    register={register}
                    errors={errors}
                  />
                  <TbRadio title={'게시 상태'} options={options} name="active" register={register} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default CreateWorkflow;
