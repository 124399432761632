import APIClient from './APIClient';
import { PageInfo } from './types';

export interface ReqPaymentList extends PageInfo {
  pid?: string;
  orderStatus?: 'INIT' | 'PAID' | 'FAILED' | 'REFUND';
  idSubscription?: number;
}
export const getPaymentList = async (params: ReqPaymentList) => {
  const response = await APIClient.get(`/v2/api/payment/page`, {
    params,
  });
  return response.data;
};
